import { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Page } from '@components';
import { AppWidgetSummary } from 'src/components/pages/app';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralAnalytics } from '@features/analytics/analytics.actions';

export default function Dashboard() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const { general } = useSelector((state) => state.analytics);

  if (!user) {
    location.href = '/login';
  }

  const getTotals = () => {
    dispatch(getGeneralAnalytics());
  };

  useEffect(() => {
    getTotals();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total User"
              total={general?.total?.user}
              icon={'ant-design:user-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total News"
              total={general?.total?.news}
              color="info"
              icon={'ant-design:border-outer-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total News Reads"
              total={general?.total?.newsReads}
              color="info"
              icon={'ant-design:border-outer-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Comment"
              total={general?.total?.newsComment}
              color="info"
              icon={'ant-design:comment-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Source"
              total={general?.total?.source}
              color="info"
              icon={'ant-design:holder-outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total IOS"
              total={general?.total?.downloads?.ios}
              color="info"
              icon="cil:apple"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Android"
              total={general?.total?.downloads?.android}
              color="info"
              icon="icon-park-outline:android"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Download"
              total={general?.total?.downloads?.total}
              color="info"
              icon="tabler:download"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
